<template>
  <div
    :class="$style.root"
    ref="root"
  >
    <ul
      :class="$style.list"
    >
      <li v-for="locale in locales" :key="locale.code">
        <NuxtLink
          :key="locale.code"
          :to="switchLocalePath(locale.code)"
          :class="$style.link"
        >
          {{ locale.code }}
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<script setup>
const root = ref(null)

const { locale: currentLocale, locales } = useI18n()
const switchLocalePath = useSwitchLocalePath()
</script>

<style module>
.root {
  position: relative;
}

.list {
  composes: reset-list font-size-x-small from global;
  display: flex;
  gap: .5ch;
}

.link {
  composes: reset-link from global;
  text-transform: uppercase;
  color: var(--color--secondary);
}

.link:hover {
  color: var(--color--primary);
}

.link[class*='router-link-exact-active'] {
  color: var(--color--primary);
}
</style>

import qs from 'qs'

export const queryFooter = () => {
  const query = qs.stringify(
    {
      populate: {
        informations: true,
        page_entries: {
          populate: {
            titles: true,
            slugs: true
          }
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `footer?${query}`
}

<template>
  <div
    :class="$style.root"
    ref="rootElement"
  >
    <div :class="$style.heading">
      Marubi <slot />
    </div>

    <ul :class="$style.group">
      <li>
        <I18nLink :class="$style.link" :to="{name: 'index'}">
          {{$t("index.title")}}
        </I18nLink>
      </li>

      <li
        v-for="page_entry in data?.data?.attributes?.page_entries?.data"
      >
        <I18nLinkWithFallback
          :class="$style.link"
          name="slug"
          :localized-data="getLocalizedData(page_entry)?.attributes"
        >
          {{ getTitle(getLocalizedData(page_entry)?.attributes) }}
        </I18nLinkWithFallback>
      </li>

      <li>
        <I18nLink :class="$style.link" :to="{name: 'visit'}">
          {{$t("tickets.title")}}
        </I18nLink>
      </li>

      <li>
        <I18nLink :class="$style.link" :to="{name: 'news'}">
          {{$t("news.title")}}
        </I18nLink>
      </li>
    </ul>

    <ul :class="$style.group">
      <li :class="$style.label">
        {{ $t("exhibitions.title") }}
      </li>
      <li
        v-for="page_entry in data?.data?.attributes?.exhibition_pages?.data"
      >
        <I18nLinkWithFallback
          :class="$style.link"
          name="exhibitions-slug"
          :localized-data="getLocalizedData(page_entry)?.attributes"
        >
          {{ getTitle(getLocalizedData(page_entry)?.attributes) }}
        </I18nLinkWithFallback>
      </li>

      <li>
        <I18nLink :class="$style.link" :to="{name: 'exhibitions'}">
          {{$t("exhibitions.title")}}
        </I18nLink>
      </li>

      <li>
        <I18nLink :class="$style.link" :to="{name: 'online-exhibitions'}">
          {{$t("online_exhibitions.title")}}
        </I18nLink>
      </li>
    </ul>

    <ul :class="$style.group">
      <li>
        <I18nLink :class="$style.link" :to="{name: 'talks'}">
          {{$t("talks.title")}}
        </I18nLink>
      </li>
    </ul>

    <ul :class="$style.group">
      <li>
        <I18nLink :class="$style.link" :to="{name: 'editions'}">
          {{$t("editions.title")}}
        </I18nLink>
      </li>

      <li>
        <I18nLink :class="$style.link" :to="{name: 'essays'}">
          {{$t("essays.title")}}
        </I18nLink>
      </li>
    </ul>

    <ul :class="$style.group">
      <li>
        <I18nLink :class="$style.link" :to="{name: 'education'}">
          {{$t("education.title")}}
        </I18nLink>
      </li>
    </ul>

    <ul :class="$style.group">
      <li :class="$style.label">
        {{ $t("archive.label") }}
      </li>
      <li>
        <I18nLink :class="$style.link" :to="{name: 'archive'}">
          {{$t("archive.title")}}
        </I18nLink>
      </li>

      <li>
        <I18nLink :class="$style.link" :to="{name: 'request-for-photographers'}">
          {{ $t("archive.rfp") }}
        </I18nLink>
      </li>
    </ul>

    <ul
      :class="$style.group"
      v-if="data?.data?.attributes?.secondary_page_entries?.data.length > 0"
    >
      <li
        v-for="page_entry in data?.data?.attributes?.secondary_page_entries?.data"
      >
        <I18nLinkWithFallback
          :class="$style.link"
          name="slug"
          :localized-data="getLocalizedData(page_entry?.attributes)"
        >
          {{ getTitle(getLocalizedData(page_entry)?.attributes) }}
        </I18nLinkWithFallback>
      </li>
    </ul>
  </div>
</template>

<script setup>
const { data, error } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryMenu(baseStrapiGetSlugQueryObjectFromRoute()) } }
  )
})

const getLocalizedData = i18nGetLocalizedData
const getTitle = (data) => i18nGetLocalizedTitle(data)
</script>

<style module lang="scss">
.root {
  composes: font-size-medium container--default from global;

  padding-top: var(--unit--vertical);
  padding-bottom: var(--unit--vertical);

  display: flex;
  flex-direction: column;
}

.heading {
  margin-bottom: var(--unit--vertical);
  text-transform: uppercase;

  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: var(--color--gray);
}

.group {
  composes: reset-list from global;

  padding: calc(var(--unit--vertical) - (var(--font-default--line-height) - var(--font-default--font-size)) + var(--stroke--default)) 0 var(--unit--vertical) 0;
  border-top: var(--stroke--default) solid var(--color--gray);
}

.label {
  composes: font-size-default from global;
  text-transform: uppercase;
  color: var(--color--gray);
}

.link {
  composes: reset-link from global;
}

.link:hover,
.link:global(.router-link-exact-active) {
  color: var(--color--yellow);
}
</style>

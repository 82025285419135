<template>
  <menu
    :class="$style.root"
    ref="rootElement"
    tabindex="0"
    @keyup.esc="$emit('onCloseMenu')"
  >
    <div
      :class="$style.container"
      ref="contentElement"
    >
      <NavigationMenuContentComponent>
        <button
          @click="$emit('onCloseMenu')"
          :class="$style.button"
        >
          <SvgoCross data-cross />
        </button>
      </NavigationMenuContentComponent>
    </div>
  </menu>
</template>

<script setup>
const nuxtApp = useNuxtApp()
const rootElement = ref(null)
const contentElement = ref(null)

const emit = defineEmits(['onCloseMenu'])

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
})

onClickOutside(contentElement, () => {
  emit('onCloseMenu')
}, { ignore: ['button', 'a'] })

nuxtApp.hook('page:start', () => { emit('onCloseMenu') })

watch(() => props?.isOpen, (val) => {
  if (val) {
    setTimeout(() => {
      rootElement.value?.focus()
      document.documentElement.setAttribute('data-overflow-hidden', '')
    }, 50)
  } else {
    document.documentElement.removeAttribute('data-overflow-hidden', '')
  }
})

</script>

<style module lang="scss">
.root {
  position: fixed;
  z-index: var(--z-index--menu);

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  margin: 0;
  padding: 0;

  height: var(--100dvh);

  overflow: scroll;

  --button-square--background-color: var(--color--black);
  --button-square--color: var(--color--white);
}

.root:focus {
  outline: none;
}

.container {
  position: relative;

  background-color: rgba(0, 0, 0, 0.875);
  color: var(--color--white);
  backdrop-filter: grayscale(1);

  min-height: 100%;
}

.top {
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: var(--unit--horizontal);

  margin-bottom: calc(var(--unit--vertical) * 3);

  position: sticky;
  top: var(--unit--vertical);
}

.button {
  composes: reset-button button-square from global;
  --button--background-color: var(--color--black);
  --button--background-color--hover: var(--color--black);
  --button--stroke: var(--color--white);
}
</style>

<template>
  <header
    :class="$style.root"
    ref="rootElement"
  >
    <button
      :class="$style['menu-button']"
      @click="$emit('onOpenMenu')"
    >
      <SvgoLogo />
    </button>

    <NavigationLanguageSwitcherComponent />
  </header>
</template>

<script setup>
const rootElement = ref(null)

const props = defineProps({
  menuOpen: {
    type: Boolean,
    default: false,
  },
})
</script>

<style module lang=scss>
.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index--site-header);

  padding: var(--unit--vertical) var(--unit--horizontal);

  display: flex;
  justify-content: space-between;

  pointer-events: none;
}

.root * {
  pointer-events: all;
}

.menu-button {
  composes: reset-button from global;
  width: calc(var(--unit--vertical) * 1.5);
  height: calc(var(--unit--vertical) * 1.5);
}

.menu-button svg {
  fill: var(--color--yellow);
  transition: fill var(--transition--default);
}

.menu-button:hover svg {
  fill: var(--color--secondary);
}
</style>

<template>
  <footer :class="$style.root">
    <div :class="$style.content">
      <div :class="$style.column">
        <div>
          <CoreTextComponent
            :string="information"
            :class="$style.information"
            v-if="information"
          />

          <ul
            :class="$style.links"
            v-if="localizedData?.page_entries?.data?.length"
          >
            <li
              v-for="(page, index) in localizedData?.page_entries?.data"
            >
              <I18nLinkWithFallback
                :localized-data="getLocalizedData(page?.attributes)"
                name="slug"
              >
                {{ getTitle(getLocalizedData(page?.attributes)) }}
              </I18nLinkWithFallback>{{ index < localizedData?.page_entries?.data?.length - 1 ? ', ' : '' }}
            </li>
          </ul>
        </div>
      </div>

      <div :class="$style.column">
        <div :class="$style.sponsor">
          {{ $t('footer.ministry_of_culture') }}
          <SvgoMeki />
        </div>
        <div :class="$style.sponsor">
          {{ $t('footer.aadf') }}
          <SvgoAADF />
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
const { data, error } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryFooter(baseStrapiGetSlugQueryObjectFromRoute()) } }
  )
})

const getLocalizedData = i18nGetLocalizedData
const getTitle = (data) => i18nGetLocalizedTitle(data)

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(data?.value?.data))
})

const information = computed(() => i18nGetLocalizedString(localizedData.value?.informations))
</script>

<style module lang=scss>
.root {
  margin-top: calc(var(--unit--vertical) * 3);
  margin-bottom: calc(var(--unit--vertical) * 1);

  max-width: 100vw;
  overflow: hidden;
}

.root a {
  text-decoration-line: none;
}

.root a:hover {
  text-decoration-line: underline;
}

.content {
  composes: container--default font-size-small from global;
  padding: var(--unit--vertical) var(--unit--horizontal);

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--unit--horizontal);
  @media (max-width: $breakpoint-s) {
    grid-template-columns: 1fr;
    grid-row-gap: var(--unit--vertical);
  }
}

.links {
  composes: reset-list from global;
}

.links * {
  display: inline;
}

.column {
  display: flex;
  flex-direction: column;
  gap: var(--unit--vertical);
}

.sponsor {
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 100px;
  grid-column-gap: var(--unit--spacer);

  @media (max-width: $breakpoint-s) {
    grid-template-columns: 1fr;
    grid-row-gap: var(--unit--vertical);
  }
}

.sponsor svg {
  height: auto;
  width: 100%;

  fill: currentColor !important;

  @media (max-width: $breakpoint-s) {
    width: 125px;
  }
}
</style>

<template>
  <div :class="$style.root">
    <div :class="$style.content">
      <transition name="slide-top">
        <NavigationMenuComponent
          v-show="menuOpen"
          :class="$style.tagLine"
          :isOpen="menuOpen"
          @onCloseMenu="menuOpen = false"
        />
      </transition>
      <NavigationSiteHeaderComponent
        :class="$style.header"
        :menuOpen="menuOpen"
        @onOpenMenu="menuOpen = true"
      />

      <main :class="$style.main">
        <slot />
      </main>

      <FooterComponent :class="$style.footer" />
    </div>
  </div>
</template>

<script setup>
  const menuOpen = ref(false)
</script>

<style module>
.root {
  min-height: var(--100svh, 100vh);

  display: flex;
  flex-direction: column;
}

.content {
  display: grid;
  grid-template-areas: 'header' 'main' 'footer';
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;

  position: relative;

  flex: 1;
}

.main {
  grid-area: main;
}

.footer {
  grid-area: footer;
}
</style>
